import {setRemoteDefinitions} from "@nx/angular/mf";

fetch("/assets/config/module-federation.manifest.json")
  .then(res => res.json())
  .then(definitions => setRemoteDefinitions(definitions))
  .then(() =>
    import("./bootstrap").catch(err => {
      // eslint-disable-next-line no-console
      console.error(err);
    })
  );
